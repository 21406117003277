import React from 'react';

import Logo from '../../assets/images/tower_logo.svg';

const Loader = ({message}) => {

  if(message){
    return (
      <div className="loader verify">
        <img src={Logo} alt="Logo" />
        <h2>{message}</h2>
      </div>
    );
  }

  return (
    <div className="loader">
      <img src={Logo} alt="Logo" />
    </div>
  );
};

export default Loader;
