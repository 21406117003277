import React from "react";
import { useTranslation } from "react-i18next";
//SVGs
import FacebookSvg from "./svg/FacebookSvg";
import LinkedinSvg from "./svg/LinkedinSvg";
import TwitterSvg from "./svg/TwitterSvg";
import YouTubeSvg from "./svg/YouTubeSvg";
import InstagramSvg from "./svg/InstagramSvg";

const Socials = ({ fill, background, socials }) => {
  const { t } = useTranslation();
  const bgColor = {
    backgroundColor: background,
  };
  return (
    <div className="socials">
      {socials ? (
        <>
          {socials.includes("facebook") && (
            <a
              href={t("links.facebook")}
              target="_blank"
              rel="noopener noreferrer"
              className="icon"
              style={bgColor}
            >
              <FacebookSvg fill={fill} />
            </a>
          )}
          {/* {socials.includes("instagram") && (
            <a
              href={t("links.instagram")}
              target="_blank"
              rel="noopener noreferrer"
              className="icon"
              style={bgColor}
            >
              <InstagramSvg fill={fill} />
            </a>
          )} */}
          {socials.includes("linkedin") && (
            <a
              href={t("links.linkedin")}
              target="_blank"
              rel="noopener noreferrer"
              className="icon"
              style={bgColor}
            >
              <LinkedinSvg fill={fill} />
            </a>
          )}
          {socials.includes("twitter") && (
            <a
              href={t("links.twitter")}
              target="_blank"
              rel="noopener noreferrer"
              className="icon"
              style={bgColor}
            >
              <TwitterSvg fill={fill} />
            </a>
          )}
          {socials.includes("youtube") && (
            <a
              href={t("links.youtube")}
              target="_blank"
              rel="noopener noreferrer"
              className="icon"
              style={bgColor}
            >
              <YouTubeSvg fill={fill} />
            </a>
          )}
        </>
      ) : (
        <>
          <a
            href={t("links.facebook")}
            target="_blank"
            rel="noopener noreferrer"
            className="icon"
            style={bgColor}
          >
            <FacebookSvg fill={fill} />
          </a>
          <a
            href={t("links.instagram")}
            target="_blank"
            rel="noopener noreferrer"
            className="icon"
            style={bgColor}
          >
            <InstagramSvg fill={fill} />
          </a>
          <a
            href={t("links.linkedin")}
            target="_blank"
            rel="noopener noreferrer"
            className="icon"
            style={bgColor}
          >
            <LinkedinSvg fill={fill} />
          </a>
          <a
            href={t("links.twitter")}
            target="_blank"
            rel="noopener noreferrer"
            className="icon"
            style={bgColor}
          >
            <TwitterSvg fill={fill} />
          </a>
          <a
            href={t("links.youtube")}
            target="_blank"
            rel="noopener noreferrer"
            className="icon"
            style={bgColor}
          >
            <YouTubeSvg fill={fill} />
          </a>
        </>
      )}
    </div>
  );
};

export default Socials;
