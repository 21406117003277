import { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { I18nContext } from "../context/I18nProvider";
import api from "../api";
import swal from "sweetalert";
//Lang
import { useTranslation } from "react-i18next";
import Translate from "../i18n/Translate";
//Comps
import SpinnerEllipsis from "./loaders/SpinnerEllipsis";
//Form
import { useForm } from "react-hook-form";
//SVGs
import WarningSvg from "./svg/WarningSvg";

const ForgotForm = () => {
    const { language } = useContext(I18nContext);
    const [loading, setLoading] = useState(false);

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm({
        mode: "onSubmit",
    });

    const { t } = useTranslation();

    const onSubmit = async (data) => {
        // console.log(data);
        setLoading(true);

        try {
            const response = await api.login.recoverPassword({
                email: data.email,
                language: language,
            });

            if (response.status) {
                swal({
                    title: "Su solicitud se ha enviado correctamente!",
                    text: "Por favor revise su correo electrónico.",
                    icon: "success",
                    button: "Cerrar",
                    timer: 8000,
                });
                //Reseteamos
                reset();
            } else {
                swal({
                    title: "Error!",
                    text: `${response.message}`,
                    icon: "error",
                    button: "Cerrar",
                    timer: 8000,
                });
            }
        } catch (error) {
            console.log("CATCH  ERROR");
            console.error(error);
            swal({
                title: "Error!",
                text: "Ha ocurrido un error, por favor intente nuevamente.",
                icon: "error",
                button: "Cerrar",
                timer: 8000,
            });
            reset();
        } finally {
            setLoading(false);
        }
    };
    //Registros de inputs al useForm();
    let emailRegistration = register("email", {
        required: `${t("form.requiredField")}`,
        pattern: {
            value: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
            message: `${t("form.wrongField")}`,
        },
    });

    return (
        <form className="form-tower" onSubmit={handleSubmit(onSubmit)} noValidate>
            <div className="input-group">
                <label htmlFor="email">
                    <Translate langkey="form.labels.email" />
                </label>

                <input type="email" id="email" {...emailRegistration} aria-invalid={errors.email ? "true" : "false"} />

                {errors.email && (
                    <span className="error-msg" role="alert">
                        {errors.email.message}
                    </span>
                )}
                {errors.email && (
                    <span className="error-icon">
                        <WarningSvg />
                    </span>
                )}
            </div>

            <button className="submit-btn btn" type="submit">
                {loading ? (
                    <SpinnerEllipsis color={"primario"} />
                ) : (
                    <span>
                        <Translate langkey="form.fortgotSend" />
                    </span>
                )}
            </button>

            {/* Para volver al login comun */}
            <Link to="/" className="back-login-btn">
                <Translate langkey="form.goSignIn" />
            </Link>
        </form>
    );
};

export default ForgotForm;
