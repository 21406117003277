//Lang
import Translate from "../i18n/Translate";
//Hooks
import useScrollTop from "../hooks/useScrollTop";
import { useInView } from "react-intersection-observer";
//Comps
import SignInForm from "../components/SignInForm";
import ForgotPasswordForm from "../components/ForgotPasswordForm";
import tower_logo from "../assets/images/tower_logo.svg";

const DOMAIN = process.env.REACT_APP_DOMAIN;

const SignIn = () => {
    const [ref1, inView1] = useInView({
        threshold: 0,
        triggerOnce: true,
    });

    useScrollTop();

    return (
        <main className="prehome-login">
            <section className="prehome-login__section">
                <div className="prehome-login__container">
                    <div className="logo">
                        <a href={`${DOMAIN}`} rel="noreferrer">
                            <img src={tower_logo} alt="tower-travel logo" />
                        </a>
                    </div>

                    <h1 ref={ref1} className={`title ${inView1 ? "appear" : ""}`}>
                        <Translate langkey="login.signInTitle" />
                    </h1>

                    <SignInForm />
                </div>
            </section>
        </main>
    );
};

export default SignIn;
