import React, { Suspense } from "react";
//Router
import { BrowserRouter, Switch, Route } from "react-router-dom";
//Context
import { I18nContextProvider } from "../context/I18nProvider";
import { AuthProvider } from "../context/AuthProvider";

//Componentes
import Layout from "../layout/Layout";
import Loader from "../components/loaders/Loader";

//Rutas
import SignIn from "../containers/SignIn";
import Signup from "../containers/Signup";
import NewPassword from "../containers/NewPassword";
import ForgotPassword from "../containers/ForgotPassword";

const BASE_URL = process.env.REACT_APP_BASENAME_URL;

function App() {
    return (
        <BrowserRouter basename={BASE_URL}>
            <Suspense fallback={<Loader />}>
                <AuthProvider>
                    <I18nContextProvider>
                        <Layout>
                            <Switch>
                                <Route exact path="/" component={SignIn} />
                                <Route exact path="/signup" component={Signup} />
                                <Route exact path="/forgotpassword" component={ForgotPassword} />
                                <Route exact path="/newpassword/:token" component={NewPassword} />
                            </Switch>
                        </Layout>
                    </I18nContextProvider>
                </AuthProvider>
            </Suspense>
        </BrowserRouter>
    );
}

export default App;
