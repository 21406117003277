import React,{ useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import { Trans } from 'react-i18next'
//Language
import { I18nContext } from '../context/I18nProvider'

const Translate =  ( {children, langkey} ) => {

    const { language } = useContext( I18nContext );

    useEffect(() => {
        // console.log("me volvi a renderizar porque cambio el lenguaje del contexto");
    }, [language])

    return (
        <Trans i18nKey={ langkey } >
            {children && children}
        </Trans>
    )
}

Translate.propTypes = {
    langkey: PropTypes.string.isRequired,
}
 
export default Translate
