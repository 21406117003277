import { useState, useContext } from "react";
import { Link } from "react-router-dom";
//Lang
import { useTranslation } from "react-i18next";
import Translate from "../i18n/Translate";
import { AuthContext } from "../context/AuthProvider";
//Form
import { useForm } from "react-hook-form";
//SVGs
import WarningSvg from "./svg/WarningSvg";
import WatchUnwatch from "./svg/WatchUnwatch";
import SpinnerEllipsis from "./loaders/SpinnerEllipsis";

const SignInForm = ({ forgotPassword, setForgotPassword }) => {
    const { setUserCredentials, isAuthenticated, validateWith } = useContext(AuthContext);

    const [watchPassword, setWatchPassword] = useState(false);

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        defaultValues: {
            email: "",
            password: "",
        },
    });

    const { t } = useTranslation();

    const onSubmit = ({ email, password }) => {
        setUserCredentials({
            email,
            password,
        });
    };

    //Registros de inputs al useForm();
    let emailRegistration = register("email", {
        required: `${t("form.requiredField")}`,
        pattern: {
            value: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
            message: `${t("form.wrongField")}`,
        },
    });

    let passwordRegistration = register("password", {
        required: `${t("form.requiredField")}`,
        pattern: {
            // value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
            message: `${t("form.wrongField")}`,
        },
    });

    return (
        <form className="form-tower" onSubmit={handleSubmit(onSubmit)} noValidate>
            <div className="input-group">
                <label htmlFor="email">
                    <Translate langkey="form.labels.email" />
                </label>

                <input type="email" id="email" {...emailRegistration} aria-invalid={errors.email ? "true" : "false"} />
                {errors.email && (
                    <span className="error-msg" role="alert">
                        {errors.email.message}
                    </span>
                )}
                {errors.email && (
                    <span className="error-icon">
                        <WarningSvg />
                    </span>
                )}
            </div>

            <div className="input-group">
                <label htmlFor="password">
                    <Translate langkey="form.labels.password" />
                </label>

                <input
                    type={`${watchPassword ? "text" : "password"}`}
                    id="password"
                    autoComplete="on"
                    {...passwordRegistration}
                    aria-invalid={errors.password ? "true" : "false"}
                />
                <span className="watch-pass" onClick={() => setWatchPassword(!watchPassword)}>
                    <WatchUnwatch watching={watchPassword} />
                </span>
                {errors.password && (
                    <span className="error-msg" role="alert">
                        {errors.password.message}
                    </span>
                )}
                {errors.password && (
                    <span className="error-icon">
                        <WarningSvg />
                    </span>
                )}
            </div>

            {/* ERROR DE AUTENTICACION */}
            {isAuthenticated.status.value === "FAILED" && (
                <p className="auth-failure-msg" role="alert">
                    {isAuthenticated.status.message}
                </p>
            )}
            {/* FORGOT PASSWORD LINK solo para quien se va a registrar con TOWER */}
            {validateWith === "tower" && (
                <Link to="/forgotpassword" className="forgot-pass-btn">
                    <Translate langkey="form.forgotPassword" />
                </Link>
            )}

            <p className="signup-link">
                <Translate langkey="form.signUpText" />
                <Link to="/signup">
                    <Translate langkey="form.signUpLink" />
                </Link>
            </p>

            <button className="submit-btn btn" type="submit">
                {isAuthenticated.loading ? (
                    <SpinnerEllipsis color={"primario"} />
                ) : (
                    <span>
                        <Translate langkey="form.signIn" />
                    </span>
                )}
            </button>
        </form>
    );
};

export default SignInForm;
